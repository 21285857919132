import React from "react";

function Disclaimer(){
    return(
        <div className="form-container">
            <h2 className="h2">Disclaimer</h2>
        <h3 className="disclaimer-head">General Information</h3>
        <p className="disclaimer-para">
        The information provided on <a href="linkdoc.online">LinkDoc</a>  is for general informational purposes only. All information on the Site is provided in good faith, however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site or our mobile application.
        </p>
        <h3 className="disclaimer-head">User-Generated Content</h3>
        <p className="disclaimer-para">
        The Site and our mobile application allow users to promote their social media links (e.g., Instagram, Telegram, Snapchat, etc.). We do not pre-screen, endorse, or approve any content or links posted by users. We are not responsible for the content, accuracy, or legality of user-generated content or links.
        </p>
        <h3 className="disclaimer-head">No Endorsement</h3>
        <p className="disclaimer-para">
        The appearance of any social media links, information, or content provided by users on the Site does not constitute an endorsement, guarantee, warranty, or recommendation by us. We do not assume responsibility or liability for the actions, products, services, or content of any social media platforms or third parties.
        </p>
        <h3 className="disclaimer-head">External Link Disclaimer</h3>
        <p className="disclaimer-para">
        The Site may contain links to other websites or content belonging to or originating from third parties. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us. We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the site or any website or feature linked in any banner or other advertising.
        </p>
        <h3 className="disclaimer-head">Limitation of Liability</h3>
        <p className="disclaimer-para">Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the Site or reliance on any information provided on the Site. Your use of the Site and your reliance on any information on the Site and our mobile application is solely at your own risk.
        </p>
        <h3 className="disclaimer-head">User Responsibility</h3>
        <p className="disclaimer-para">
        Users are solely responsible for the content they post and for ensuring that their promotions comply with all applicable laws, regulations, and platform policies. We reserve the right to remove any content that we determine in our sole discretion to be unlawful, offensive, or otherwise inappropriate.
        </p>
        <h3 className="disclaimer-head">
        Changes to This Disclaimer
        </h3>
        <p className="disclaimer-para">
            We may update our Disclaimer from time to time. We will notify you of any changes by posting the new Disclaimer on this page. You are advised to review this Disclaimer periodically for any changes. Changes to this Disclaimer are effective when they are posted on this page.
        </p>
        </div>
    )
}

export default Disclaimer;;