import React from "react";
import { Routes, Route } from 'react-router-dom';
import Disclaimer from "../pages/Disclaimer";
import Form from "./Form";
import Body from "../pages/Body";
import Header from "./Header";
import PrivacyPolicy from "../pages/Privacy-policy";
import Footer from "./Footer";
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react"


function App() {
    
    return (
        
        <div>
            <Header />  
            <Routes>
                <Route path="/home" element={<Body /> } />
                <Route path="/form" element={<Form />} />
                <Route path="/disclaimer" element={<Disclaimer />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            </Routes>
            <Footer /> 
            <Analytics />
            <SpeedInsights />
        </div>
        
    )
}

export default App;