import React from "react";
import { useNavigate } from 'react-router-dom';


function Header(){
     const navigate = useNavigate();
    return(
        <header>
            <h1 onClick={() => navigate('/home')} className="title">LinkDoc</h1>
            <nav className="header-nav">
            <ol>
                <li className="header-home"   onClick={() => navigate('/home')}><a href="./home">Home </a></li>
                <li className="header-add"    onClick={() => navigate('/form')}><a href="./form">Add </a></li>
                <li className="close-visible" onClick={() => navigate('/disclaimer')}><a href="./disclaimer">Disclaimer </a></li>
                <li className="close-visible" onClick={() => navigate('/privacy-policy')}><a href="./privacy-policy">Privacy Policy</a></li>
                {/* <li onClick={() => navigate('/terms-and-condition')}><a href="./terms-and-condition">T&C</a></li> */}
            </ol>
            </nav>
        </header>
    )
}

export default Header;