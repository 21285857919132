import React from "react"
import { TiArrowUpOutline } from "react-icons/ti";
import { IoIosWarning } from "react-icons/io";


function Card(props){
    return(
        <div className="card card-mobile">
            <h3 >{props.title}</h3>
            <p className="description">{props.description}</p>  
            <div className="link-container">
                <a href={props.link}  target="_blank" rel="noopener noreferrer" className="linkButtonStyle">Link</a>
               
                <div className="icons-container">
                
                {/* <div className="like">
                    <TiArrowUpOutline color="white" size={"20px"} />
                    <p>1</p>
                </div>
                <div className="warning">
                    <IoIosWarning  color="yellow" size={"20px"} />
                    <p>2</p>
                </div>   */}
                </div> 
            </div>  
        </div>
        
    )
}

export default Card;