import React, {  useState, memo } from "react";
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';


const FormInput = memo( function FormInput(){

    const [formFull, setFormFull] = useState({
        titleInput: "",
        linkInput: "",
        categoryInput:"",
        descInput:""
    });
    const [inputTextColor, setInputTextColor] = useState('default');
    const [capVal, setCapVal] = useState(null)
    const navigate = useNavigate();
    const captchaSiteKey = process.env.REACT_APP_GOOGLE_CAPTCHA
    const [submitchange, setSubmitChange] = useState('Submit')
    const API_URL = process.env.REACT_APP_DEPLOYED_LINK
   
    
    function handleChange(event) {
        const {name, value} = event.target
        setFormFull((prevValue) => {
            return{
                ...prevValue,
                [name]: value
            }
        })
    };

    async function handleSubmit(event) {
        event.preventDefault();
        
      
        try {
          if (!capVal) {
            alert('Please verify captcha');
            return; 
          }
          setSubmitChange('Loading..');
          const startTime = Date.now(); 
          const response = await fetch(`${API_URL}submit-form`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formFull),
          });
          if (response.ok) {
            console.log("Response was ok");
        }
          const elapsedTime = Date.now() - startTime;
          if (elapsedTime > 3000) {
            throw new Error('Request timed out, Try after sometime');
          }
          alert("Form submit Successful");
          navigate('/home');
         } catch (error) {
          console.error(error.message);
          alert(`Form submit failed: ${error.message}`);
          navigate('/home');
        }
      }
      

        function handleColorChange(){ 
            if (formFull.categoryInput !== '') {
            setInputTextColor('white');
            } else {
            setInputTextColor('default'); 
            }
        }
     
   
 
    return(
            <div className="form-container">
                <form onSubmit={handleSubmit} >
                <h4>Add/Promote your Link here</h4>
                <div className="form-group">
                <label for="title">Title *</label>
                <input name="titleInput" 
                    required="required"  
                    maxLength={25}
                    className="form-input"  
                    onChange={handleChange} 
                    placeholder="Enter the Name or Title"   
                    value={formFull.titleInput} /><br/>
                </div>

                <div className="form-group">
                <label for="link">Provide your link here *</label>
                    <input name="linkInput"  
                    required="required"
                    type= "url"
                    className="form-input"   
                    onChange={handleChange} 
                    placeholder="Enter the Link"            
                    value={formFull.linkInput}/><br/>
                </div>

                <div className="form-group">
                <label for="category">Select your category *</label>
                <select name="categoryInput" 
                    className="custom-select" 
                    required="required"
                    onChange={handleChange} 
                    onClick={handleColorChange}
                    placeholder="Select the group category" 
                    value={formFull.categoryInput} 
                    style={{ color: inputTextColor }}
                >
                    <option color="black" value="">Select the category</option>
                    <option value="Instagram">Instagram</option>
                    <option value="Whatsapp">Whatsapp</option>
                    <option value="Snapchat">Snapchat</option>
                    <option value="Linkedin">LinkedIn</option>
                    <option value="Telegram">Telegram</option>
                    <option value="YouTube">YouTube</option>
                    <option value="Facebook">Facebook</option>
                    <option value="Twitter">Twitter</option>
                    <option value="GitHub">GitHub</option>
                    <option value="Discord">Discord</option>
                </select><br/>
                </div>

                <div className="form-group">
                <label for="Description">Description *</label>
                 <input name="descInput" 
                    className="form-input"  
                    required="required"  
                    maxLength={255}
                    onChange={handleChange} 
                    placeholder="Description in 100 words"  
                    value={formFull.descInput}/>
                </div>
                <ReCAPTCHA className="captcha"
                sitekey={captchaSiteKey}
                onChange={(val) => setCapVal(val)}
                />
                <button type="submit"  className="form-submit-btn">{submitchange}</button>  
            </form>
          </div>
         )
      }
    )

export default FormInput;
