import React from "react";

function PrivacyPolicy(){
    return(
        <div className="form-container">
             <h2>Privacy Policy</h2>
             <h3 className="disclaimer-head">Introduction</h3>
            <p className="disclaimer-para"> This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our Site. Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the Site.
            </p>

            <h3 className="disclaimer-head">Personal Data</h3>
            <p className="disclaimer-para"> We don't collect personally identifiable information, such as your name, email address, and social media handles, when you voluntarily register with the Site or when you choose to share such information through your profile.
            </p>

            <h3 className="disclaimer-head">Usage Data</h3>
            <p className="disclaimer-para"> We may also collect information that your browser sends whenever you visit our Site. This Usage Data may include information such as your computer's Internet Protocol (IP) address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages, and other diagnostic data.
            </p>

            <h3 className="disclaimer-head">Security of Your Information</h3>
            <p className="disclaimer-para"> We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.</p>
           

            <h3 className="disclaimer-head">Changes to This Privacy Policy</h3>
            <p className="disclaimer-para"> We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
            </p>

            <h3 className="disclaimer-head">Contact Us</h3>
            <p className="disclaimer-para"> If you have any questions about this Privacy Policy, please contact us: alphalinfoxtrot@gmail;.com
            </p>
            
        </div>
    )
}

export default PrivacyPolicy;