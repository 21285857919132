import React from "react";
import Card from "../components/card";
import NavCard from "../components/NavCard";
import { useEffect, useState, } from "react";
import axios from 'axios';
import Feedback from '../components/Feedback'


function Body() {
    const [data, setData] = useState([])
    const [selectedCategory, setSelectedCategory] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isNavOpen, setIsNavOpen] = useState(false)
    const API_URL = process.env.REACT_APP_DEPLOYED_LINK

    

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${API_URL}?category=${selectedCategory}`);
                const sortData= response.data.sort((a,b) => b.id - a.id)
                console.log('Fetched Data:', response.data); 
                setData(sortData)
                setIsLoading(false);
            } catch (error) {
                console.error('Failed to fetch data: ', error.message);
            }
        };
        fetchData(); 
    }, 
    [selectedCategory]);


    function createCard(data) {
        return (
            <Card
                key={data.id}
                title={data.title}
                description={data.description}
                link={data.link}             
            />
        )
    }

    const filteredData = data.filter(item => item.category === selectedCategory).map(createCard);

    const handleCategoryChange = (newCategory) => {
        if (newCategory !== selectedCategory){
            setSelectedCategory(newCategory);
            setIsLoading(true);
        } else {
            setSelectedCategory(newCategory);
            setIsLoading(false)
        }
    }

    return (
            <div className="bodyArea" >    
            <NavCard  
            isNavOpen={isNavOpen} 
            setIsNavOpen={setIsNavOpen} 
            selectedCategory={selectedCategory} 
            setSelectedCategory= {(newCategory) => {
                setSelectedCategory(newCategory);
                handleCategoryChange(newCategory)
            }}
            />
            <div className="rightSideBar">
    
            <Feedback />
            </div>
            <div className="card-Body" style={{filter: isNavOpen? 'blur(2px)' : 'none'}}>
            { isLoading 
            ? (<div className="loader loading-div"></div>) 
            : (selectedCategory === ''
            ? data.map(createCard) 
            : (filteredData)
            )
        }
            </div>  
        </div>
    )
}

export default Body;
