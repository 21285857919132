import React from "react"
import FormInput from "../pages/FormInput";


function Form() {


    return (
        <div method="/">
            <FormInput />
        </div>
    )
}

export default Form;