import React from "react";

function Footer(){
    return(
        <div className="footer-div">
            <p className="footer-name">All rights reserved   </p>
            <img className="footer-img" src="assets/copyright-16.png" alt="copyright" />
            <p className="footer-name"> 2024</p>
        </div>
    )
}

export default Footer;