import React, { useEffect, useRef, useCallback  } from "react";
import { IoIosMenu } from "react-icons/io";



function NavCard({isNavOpen, setIsNavOpen, selectedCategory, setSelectedCategory}){

    const sideNavRef = useRef(null);   
    const handleClickOutside = useCallback((event) => {
        if (sideNavRef.current  && !sideNavRef.current.contains(event.target) ) {
          setIsNavOpen(false); 
        } 
    }, [setIsNavOpen]);


    function toggleNavbar(){ 
        setIsNavOpen(!isNavOpen);
    }


    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
          document.removeEventListener('touchstart', handleClickOutside);
        };
      }, [handleClickOutside]);




    return(
        <div>
            <IoIosMenu 
            size={"24px"} 
            color="white" 
            className="navbar-toggler" 
            type="button" 
            onClick={toggleNavbar}
            
            />

            <div className="category-name" >
                <p> {selectedCategory}</p>
            </div>


            <nav 
            className={` sideBar  navbar ${isNavOpen? 'navbar-open' : 'navbar-close'}`}
                ref={sideNavRef} 
            >

            <ul className="nav-list" >
                {/* <li className="media-list navbar-list" onClick={() => setSelectedCategory('')}><img src="assets/home-5-16.png" alt="home" /><span className="navbar-text"> Home</span></li> */}
                <li className="media-list navbar-list" onClick={() => setSelectedCategory('Whatsapp')}><img src="assets/whatsapp-16.png" alt="Whatsapp"/><span className="navbar-text"> WhatsApp</span> </li>
                <li className="media-list navbar-list" onClick={() => setSelectedCategory('Instagram') }><img src="assets/instagram-16.png" alt="Instagram"/> <span className="navbar-text"> Instagram</span> </li>
                <li className="media-list navbar-list" onClick={() => setSelectedCategory('Snapchat')}><img src="assets/snapchat-2-16.png" alt="Snapchat"/> <span className="navbar-text"> Snapchat</span> </li>
                <li className="media-list navbar-list" onClick={() => setSelectedCategory('Linkedin')}><img className="linkedin-img" src="assets/linkedin-16.png" alt="LinkedIn"/> <span className="navbar-text"> LinkedIn</span> </li>
                <li className="media-list navbar-list" onClick={() => setSelectedCategory('Telegram')}><img src="assets/telegram-16.png" alt="Telegram"/> <span className="navbar-text"> Telegram</span> </li>
                <li className="media-list navbar-list" onClick={() => setSelectedCategory('YouTube')}><img src="assets/youtube-16.png" alt="Youtube"/> <span className="navbar-text"> YouTube</span> </li>
                <li className="media-list navbar-list" onClick={() => setSelectedCategory('Facebook')}><img src="assets/facebook-16.png" alt="Facebook"/> <span className="navbar-text"> Facebook</span> </li>
                <li className="media-list navbar-list" onClick={() => setSelectedCategory('Twitter')}><img src="assets/twitter-x-16.png" alt="Twitter"/> <span className="navbar-text"> Twitter</span> </li>
                <li className="media-list navbar-list" onClick={() => setSelectedCategory('GitHub')}><img src="assets/github-11-16.png" alt="GitHub"/> <span className="navbar-text"> GitHub</span> </li>
                <li className="media-list navbar-list" onClick={() => setSelectedCategory('Discord')}><img src="assets/discord-16.png" alt="Discord"/> <span className="navbar-text"> Discord</span> </li>
            </ul>
        </nav>
    </div>
        
    )
}

export default NavCard;