import axios from "axios";
import React from "react";
import { useState } from "react";


function FeedbackForm(){

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [feedback, setFeedback] = useState('')
    const webhookURL = "https://discord.com/api/webhooks/1262016922401112095/GVoIf2iMQv8ZcHKvCmVUgCBGsFnpPkDE8DC81FQ-rHhfdtSengMYcJYdD3zw_qFM0Ora";


    const handleSubmit = async(e) => {
        e.preventDefault();
        const payload = JSON.stringify({
            content:  `*New Feedback*\n\n**Name:** ${name}\n**Email:** ${email}\n**Feedback:**\n${feedback}`,
        });
        try{
            await axios.post(webhookURL, payload, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            alert('Feedback sent successfull!')
        
        } catch (error){
            console.error('Error sending feedback', error);
            alert("failed to send feedback!")
        }

    }

    return (
        
    <form className="feedback-form" onSubmit={handleSubmit}>
        <h3>Love your feedback!</h3>
      <label className="feedback-headers"  htmlFor="name">Name:</label>
      <input className="feedback-input" type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} required />

      <label className="feedback-headers" htmlFor="email">Email:</label>
      <input className="feedback-input" type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />

      <label className="feedback-headers" htmlFor="feedback">Feedback:</label>
      <textarea className="feedback-text" id="feedback" value={feedback} onChange={(e) => setFeedback(e.target.value)} required></textarea>

      <button className="feedback-submit" type="submit">Submit</button>
    </form>
  );
}

export default FeedbackForm;